<template>
  <div class="row">
    <div class="col-auto d-flex flex-column gap-1">
      <button class="btn btn-white d-flex align-items-center py-50 pl-0">
        <div
          style="width: 36px; height: 36px;"
          class="d-flex align-items-center justify-content-center rounded mr-75 bg-primary text-white"
        >
          1
        </div>
        <span class="font-weight-bolder text-primary">Sale</span>
      </button>
      <RouterLink :to="`/sale/${$route.params.id}/products`">
        <button class="btn btn-white d-flex align-items-center py-50 pl-0">
          <div
            style="width: 36px; height: 36px;"
            class="d-flex align-items-center justify-content-center rounded mr-75 border text-secondary"
          >
            2
          </div>
          <span class="font-weight-bolder">Produk</span>
        </button>
      </RouterLink>
    </div>

    <div class="col">
      <b-card>
        <FormBuilder
          label="Sale"
          update
          :data="$store.state.sale.detailData"
          :fields="fields"
          :loading-detail="$store.state.sale.loadingDetail"
          :loading-submit="$store.state.sale.loadingSubmit"
          @load="loadData"
          @submit="submitData"
          @reset="resetData"
        />
      </b-card>
    </div>
  </div>
</template>

<script>
import FormBuilder from '@/layouts/components/FormBuilder.vue'

import {
  BCard,
} from 'bootstrap-vue'

import fields from '@/field/form/sale'

export default {
  components: {
    BCard,
    FormBuilder,
  },
  setup() {
    return {
      fields,
    }
  },
  methods: {
    loadData(id) {
      return this.$store.dispatch('sale/getDetail', id)
    },
    submitData(id, data, callback) {
      this.$store.dispatch('sale/update', { id, data })
        .then(callback)
    },
    resetData() {
      return this.$store.commit('sale/SET_DETAIL_DATA', undefined)
    },
  },
}
</script>

<style scoped>

</style>
